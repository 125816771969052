import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 8-8-8\\@75% 1RM`}</p>
    <p>{`Pendlay Rows 8-8-8\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 reps each of:`}</p>
    <p>{`Wall Balls (20/14)`}</p>
    <p>{`Pullups`}</p>
    <p>{`Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed this Saturday for Thunder.  East will be
open and we’ll have classes at 9:00 & 10:00am (instead of the usual 10 &
11). `}</em></strong></p>
    <p>{`*`}<strong parentName="p">{`CrossFit kids will be cancelled this Saturday as well.`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 29th.  For more info
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      